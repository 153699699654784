import React from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import {Container, Header, Grid} from 'semantic-ui-react';
//import { Link } from 'gatsby'
import * as home from  '../styles/home.module.scss';
import logo from "../images/svg/elitepro-creatve-logo.svg";




/*export default function Home() {
  return <div>Dada Musicfackture</div>
}*/

const IndexPage = () =>{
  return (
    <Layout page="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Elitepro Creative - Strategic Approach to a Distinct Identity</title>
        <meta name="description" content="Elitepro Creative is a design and marketing agency."></meta>
      </Helmet>
      
      
        {/*<div className={home.hero}></div>*/}
      <Container fluid>
        <Grid stackable padded> 
            <Grid.Row columns={3} className={home.hero}>
              <Grid.Column className={home.left} >
              <div className="wrapper">
              <img src={logo} alt="Logo" />
              <p>Strategic Approach to a Distinct Identity</p>
              </div>
                  
              </Grid.Column>
              <Grid.Column className={home.middle}>
                <div className={home.wrapper}>
                  <Header as="h3">Strategic Approach to Problem-solving</Header>
                  <p>Elitepro Creative has a strategic approach to creating a positive impact while assisting clients to be more competitive within the marketplace. We solve businesses ' digital problems by utilizing proven methodologies. You can rely on our interdisciplinary teams of designers, developers, marketers, and researchers to solve your most critical problem. </p>
                </div>
              </Grid.Column>
              <Grid.Column className={home.right} >
                <div className={home.wrapper}>
                <Header as="h3">Relationships that Foster Excellence</Header>
                <p>At Elitepro Creative, we understand value creations is more than providing products and services to our clients. Our culture is rooted in strengthening the relationship between clients, audience, and Elitepro Creative. </p>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} className= {home.ramps} >
              <Grid.Column className={home.left_ramp}>
                <div className={home.wrapper}>
                  <Header as="h3">Design and Technology</Header>
                  <p>We use human-centered design to effectively solve every design challenge—from designing strong brands to building exceptional products and identifying performance gaps to improving business processes.</p>
                  <div className={home.links}>
                    {/*<Link to="/services/design" class="ui button">Read More</Link>*/}
                   </div>
                </div>
              </Grid.Column>
              
              <Grid.Column className={home.right_ramp}>
                <div className={home.wrapper}>
                  <Header as="h3">Marketing</Header>
                  <p>We balance analytic and intuitive approaches to allow our clients to explore and exploit opportunities—from conducting market research to providing effective digital marketing strategies and conducting audits to positioning your business in various digital channels. </p>
                  <div className={home.links}>
                    {/*<Link to="/services/marketing" className="ui button" >Read More</Link>*/}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
        </Grid>
      </Container>

      

    </Layout>
  )
}

export default IndexPage