// extracted by mini-css-extract-plugin
export var ui = "home-module--ui--1dW9E";
export var grid = "home-module--grid--dv2QH";
export var row = "home-module--row--z4R6s";
export var hero = "home-module--hero--K4NTb";
export var wrapper = "home-module--wrapper--3TbIL";
export var right = "home-module--right--5ybZg";
export var middle = "home-module--middle--49Y2l";
export var left = "home-module--left--phUs8";
export var ramps = "home-module--ramps--yNFf4";
export var left_ramp = "home-module--left_ramp--c1rL0";
export var right_ramp = "home-module--right_ramp--LndRc";
export var links = "home-module--links--tI7Cd";